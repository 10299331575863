import React from 'react';
import {ErrorComponent} from '@stjudecloud/ui-react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="Not Found" />
    <ErrorComponent />
  </Layout>
);

export default NotFoundPage;
